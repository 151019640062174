import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
} from "vue-router/auto";
import { routes } from "vue-router/auto-routes";

import { useStore } from "@/store/stores/scaffolding";
import { Account } from "@/types/account";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

type RouterOutcome = "LOGIN" | "EMAILS";

const getRouterOutcome = (
  to: RouteLocationNormalized,
): RouterOutcome | null => {
  const store = useStore();
  const account = store.account as Account | null;
  const isLoggedIn = account?.id !== null && account?.id !== undefined;

  if (!isLoggedIn && !to.path.startsWith("/oauth")) {
    return "LOGIN";
  }

  return null;
};

router.beforeEach((to, _, next) => {
  const outcome = getRouterOutcome(to);
  if (outcome === "LOGIN") {
    window.location.href = "/login?next=" + to.path;
    return;
  }
  next();
});

export default router;
